import React from 'react';
import NavBarPrincipal from './navbarprincipal';
import Footer from './footer';
import ListaProdutos from './listaprodutos';

import './App.css';

function App() {
  return <div>
    <NavBarPrincipal/>
    <ListaProdutos/>
    <Footer desenvolvidopor="ZaptVupt - Tecnologia em Serviços"/>
    </div>;
}

export default App;