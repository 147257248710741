import React from 'react';

function ListaProdutos(props){
    return <div class="text-center text-white">
       
       <img id="logoprincipal" src="images/agenda.png" class="img-fluid rounded mx-auto d-block" alt="ZaptVupt - Tecnologia em Serviços"></img>
       <img id="logoprincipal" src="images/atendimento.png" class="img-fluid rounded mx-auto d-block" alt="ZaptVupt - Tecnologia em Serviços"></img>
       <img id="logoprincipal" src="images/delivery.png" class="img-fluid rounded mx-auto d-block" alt="ZaptVupt - Tecnologia em Serviços"></img>
       <img id="logoprincipal" src="images/publi.png" class="img-fluid rounded mx-auto d-block" alt="ZaptVupt - Tecnologia em Serviços"></img>
       <img id="logoprincipal" src="images/licensemanager.png" class="img-fluid rounded mx-auto d-block" alt="ZaptVupt - Tecnologia em Serviços"></img>
       <p>{props.desenvolvidopor}</p> 
       
       </div>;   
 }
   
export default ListaProdutos;