import React from 'react';
import './App.css';

function Footer(props){
    return     <div class="text-center text-white">
        
            <hr/>
       <img id="logoprincipal" src="images/zaptvupt2.png" class="img-fluid rounded mx-auto d-block" alt="ZaptVupt - Tecnologia em Serviços"></img>
 


       <a class="text-light" href="https://zaptvupt.com.br/">© 2021 Copyright: {props.desenvolvidopor}</a>

       </div>;

 } 


export default Footer;
